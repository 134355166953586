
import axios from "axios";

let baseUrl = process.env.REACT_APP_BASE_URL;

export const regisTration = async (data) => {
  let res = await axios.post(`${baseUrl}api/auth/registartion`, data);
  return res.data;
};

export const loginUser = async (data) => {
  console.log("data====>", data);
  let res = await axios.post(`${baseUrl}api/auth/login`, data);
  return res.data;
};

export const getResult = async (data) => {
  console.log("data====>", data);
  let res = await axios.post(`${baseUrl}api/u/main/get-result`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const getAllSet = async () => {
  let res = await axios.get(`${baseUrl}api/u/main/get-all-set`, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const getQNSet = async (data) => {
  let res = await axios.post(`${baseUrl}api/u/main/get-all-question-answare-by-set`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const forgotPassword = async (data) => {
  let res = await axios.post(`${baseUrl}api/auth/forgot-password`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};

export const forgotPasswordVerify = async (data) => {
  let res = await axios.post(`${baseUrl}api/auth/forgot-password-varify`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};

export const profileDetail = async () => {
  let res = await axios.get(`${baseUrl}api/u/main/me`, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const getAllQuestion = async () => {
  let res = await axios.get(`${baseUrl}api/u/main/get-all-health-question`, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const createAnswerSet = async (data) => {
  let res = await axios.post(`${baseUrl}api/u/main/create-answare-set`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const createAnswer = async (data) => {
  let res = await axios.post(`${baseUrl}api/u/main/create-answare`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token"),
    },
  });
  return res.data;
};

export const customUserRegister = async (data) => {
  let url = data.Id
    ? `${baseUrl}Master/Register?id=${data.Id}&username=${data.UserName}&password=${data.Password}&name=${data.Name}&email=${data.Email}&role=${data.RoleId}&active=${data.Active}`
    : `${baseUrl}Master/Register?username=${data.username}&password=${data.password}&name=${data.name}&email=${data.email}&role=${data.role}&active=${data.active}`;

  let res = await axios.get(url);
  return res.data;
};

