import React from "react";

export default function Footer() {
  return (
    <footer class="acfooter">
      <img class="footerheart" src="assets/images/footer_heart.png" alt="" />
      <div class="container">
        <div class="row gy-5">
          <div class="col-md-6">
            {/* <div
              class="footerabout wow animate__fadeInLeft"
              data-wow-duration="1s"
            >
              <h6>Did You Know?</h6>
              <p>
                18 million people die of Cardiovascular Diseases each year
                across the world; that’s approximately 35 deaths every single
                minute. Over half of these deaths are preventable; however,
                health care systems often fail to provide timely interventions
                and effective preventive care.
              </p>
            </div> */}
          </div>
          <div class="col-md-3 col-sm-6 col-6">
            <div class="footerlinks">
              <h3 class="footertitle">Menu</h3>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us </a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy </a>
                </li>
                <li>
                  <a href="/terms-condition">Terms And Condition </a>
                </li>
                <li>
                  <a href="/legal-Disclaimer">Legal Disclaimer </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-6">
            <div class="footerlinks">
              <h3 class="footertitle">Social Media</h3>
              <ul class="social">
                <li>
                  <a href="#">
                    <i class="fa-brands fa-facebook-f"></i>Facebook
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="assets/images/x-handle.svg" alt="" />
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i class="fa-brands fa-youtube"></i>Youtube{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="copyrights text-center wow animate__fadeInUp" data-wow-duration="1s">
                <span>© 2024 Heart Vantage. All Rights Reserved.</span> <span className="vrline"> </span> <span>Images by Freepik</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
