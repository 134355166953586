// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Nav from "../Common/Nav";
// import Footer from "../Common/Footer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import FooterTop from "../Common/FooterTop";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
const HeartAttack = () => {
  // const [passwordVisible, setPasswordVisible] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setPasswordVisible(!passwordVisible);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Forgot Password"} route={"/forgot-password"} />
          <div className="card customlogcard">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Enter confirmation code</h2>
              <p className="subtext">
                Enter the code we have sent to your email address <a href="mailto:johndoe@gmail.com">johndoe@gmail.com</a>
              </p>
              <form action="#">
                <div className="input-group otpwrap">
                  <input title="number" className="form-control" id="codeBox1" type="number" maxlength="1" onkeyup="onKeyUpEvent(1, event)" onfocus="onFocusEvent(1)" />
                  <input title="number" className="form-control" id="codeBox2" type="number" maxlength="1" onkeyup="onKeyUpEvent(2, event)" onfocus="onFocusEvent(2)" />
                  <input title="number" className="form-control" id="codeBox3" type="number" maxlength="1" onkeyup="onKeyUpEvent(3, event)" onfocus="onFocusEvent(3)" />
                  <input title="number" className="form-control" id="codeBox4" type="number" maxlength="1" onkeyup="onKeyUpEvent(4, event)" onfocus="onFocusEvent(4)" />
                </div>
                <div className="forgot-link">
                  <a className="custombtn btn-2 hover-filled-slide-down" href="password-reset.html">
                    <span>Sign In</span>{" "}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default HeartAttack;
