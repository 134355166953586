import React, { useState } from "react";
import FooterTop from "./FooterTop";
import ScreenPlay from "./ScreenPlay";
import { useNavigate } from "react-router";
const AboutUs = () => {
  let navigate = useNavigate();
  const closeModal = () => {
    // e.preventDefault();
    navigate("/login");
  };
  const [showModal, setModal] = useState(false);
  const handleAssigment = () => {
    // e.preventDefault();
    let token = sessionStorage.getItem("token");
    if (!token) {
      setModal(!showModal);
    } else {
      navigate("/health-assessment");
      setModal(false);
    }
  };
  return (
    <div>
      <div className="inner-banner">
        <img className="corner-shadow" src="assets/images/cornershadow.png" alt="" />
        <div className="inner-banner-text-area">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="inner-banner-txt" data-wow-duration="1s">
                  <ul>
                    <li>
                      <a href="/home">Home</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="active">
                        About Us
                      </a>
                    </li>
                  </ul>
                  <h1>About My Story</h1>
                </div>
              </div>
              <div className="col-md-6 text-lg-end">
                <div className="inner-banner-img">
                  <img src="assets/images/aboutbanner.png" alt="inner-banner-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Heart Assessment
                </h5>
                <button type="button" style={{ border: "unset" }} className="close" onClick={(e) => handleAssigment(0)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">You Need to Logged In.</div>
              <div className="modal-footer">
                <button type="button" className="btn rounded-pill text-white" style={{ backgroundColor: "#06bcaf", padding: "10px 20px" }} onClick={(e) => closeModal(0)}>
                  Log In
                </button>
                <button type="button" className="btn rounded-pill text-white" style={{ backgroundColor: "#2a4c8b", padding: "10px 20px" }} onClick={(e) => handleAssigment(0)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <section className="sec2 aboutsec cs">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <figure>
                <img src="assets/images/support-image-five.png" alt="" />
              </figure>
            </div>
            <div className="col-md-8">
              <div className="sec2right">
                <div className="section-header">
                  <h2>About Me</h2>
                  <p>
                    Hi, I'm Rudransh, and my journey with heart health began in 2022 when I lost my grandmother to a sudden heart attack. Her passing left a deep void in my life and raised lingering questions: How could this have been prevented? Despite regular check-ups, an undiagnosed artery
                    blockage claimed her life.{" "}
                  </p>
                  <p>
                    Determined to make a difference, I began researching and interviewing physicians which led me to discover the lack of preventive heart care in health systems. Essential diagnostic tests like CT Coronary Angiograms are rarely prescribed due to high costs and outdated protocols,
                    leading to undiagnosed blockages being the reason behind 85% of fatal heart attacks. After learning this, I realized the need for a proactive approach to heart health.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="sec2right">
                <p>
                  Combining my passion for technology with this mission, I developed HeartVantage, a mobile app designed to empower individuals to monitor their heart health. The app offers lifestyle tips, heart assessments, and guidance on early diagnostic tests to detect blockages before they
                  become life-threatening.
                </p>
                <p>Through HeartVantage, I aim to bridge the gap between awareness and action, making preventive care accessible to all. If even one life is saved, I’ll know my grandmother’s legacy lives on. Let’s keep our hearts strong—together!</p>
                <p> On the technical front, I built the backend of HeartVantage using Bun, Huno, and MongoDB, ensuring a robust and scalable architecture. For the mobile application, I used Flutter, enabling a seamless and user-friendly experience across platforms.</p>
                <h5>My Skill</h5>
                <ul>
                  <li>UI Design in Figma</li>
                  <li>Frontend Design in HTML5 & CSS</li>
                  <li>Front-end Development in React</li>
                  <li>MongoDB, Bun Hono</li>
                  <li>Mobile App Technology Flutter</li>
                </ul>
                <div>
                  <a href="/contact-us" className="custombtn btn-1 hover-filled-slide-down">
                    <span>Contact Me</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img className="righthalf" src="assets/images/rightnd.png" alt="" />
        </div>
      </section>
      <section className="featuresection">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="featuresectionleft">
                <div className="section-header">
                  <h2>My App Feature</h2>
                  <p>The mobile version of this app will have following features:</p>
                </div>
                <ul>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-one.svg" alt="" />
                    </span>{" "}
                    Pre-Test Results
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-two.svg" alt="" />
                    </span>
                    Re-Test Health Assessment
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-three.svg" alt="" />
                    </span>
                    Heart Attack Info
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-four.svg" alt="" />
                    </span>
                    Lifestyle & Diet Info
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-five.svg" alt="" />
                    </span>
                    Health Diagnostic
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-six.svg" alt="" />
                    </span>
                    Help & Support
                  </li>
                </ul>

                <button onClick={handleAssigment} className="custombtn btn-1 hover-filled-slide-down">
                  <span>Heart Assessment</span>
                </button>
              </div>
            </div>
            <div className="col-md-5">
              <figure>
                <img src="assets/images/support-image-two.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutsec3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <figure>
                <img src="assets/images/support-image-four.png" alt="" />
              </figure>
            </div>
            <div className="col-md-7">
              <civ className="aboutsec_thrigth">
                <h2>My Mission</h2>
                <p>
                  My Mission at HeartVantage is to empower individuals to assume responsibility for their own heart health through the provision of readily available, comprehensive and tailored information. HeartVantage is dedicated to encouraging good heart health in the community, preventing heart
                  diseases and creating an informed and proactive community of users. Our goal is to make sure that everyone values cardiovascular well-being by offering dependable insights and practical guidance.
                </p>
              </civ>
            </div>
          </div>
        </div>
      </section>

      <ScreenPlay />
      <FooterTop />
    </div>
  );
};

export default AboutUs;
