import React, { useState } from "react";
import FooterTop from "../Common/FooterTop";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import { useNavigate } from "react-router";
const PasswordReset = () => {
  let navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);

  // Handle input change and move focus to the next input
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`codeBox${index + 2}`).focus();
      }
    }
  };

  const handleKeyUp = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      document.getElementById(`codeBox${index}`).focus();
    }
  };

  // Submit the OTP
  const handleSubmit = (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    sessionStorage.setItem("otp", enteredOtp);
    navigate("/create-new-password");
    console.log("Entered OTP:", enteredOtp);
  };

  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Forgot Password"} route={"/forgot-password"} />
          <div className="card customlogcard">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Enter confirmation code</h2>
              <p className="subtext">
                Enter the code we have sent to your email address <a href={`mailto:${sessionStorage.getItem("email")}`}>{sessionStorage.getItem("email")}</a>
              </p>
              <form onSubmit={handleSubmit}>
                <div className="input-group otpwrap">
                  {otp.map((value, index) => (
                    <input key={index} title="number" className="form-control" id={`codeBox${index + 1}`} type="text" maxLength="1" value={value} onChange={(event) => handleInputChange(index, event)} onKeyUp={(event) => handleKeyUp(index, event)} />
                  ))}
                </div>
                <div className="forgot-link">
                  <button type="submit" className="custombtn btn-2 hover-filled-slide-down">
                    <span>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default PasswordReset;
