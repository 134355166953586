import React, { useState, useEffect } from "react";
import { getAllQuestion, createAnswerSet, createAnswer, getResult } from "../../slices/action.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
const HealthAssessmentContainer = () => {
  let navigate = useNavigate();
  const [questionall, setQuestionSet] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    getAllquestion();
  }, []);

  async function getAllquestion() {
    try {
      let data = {
        setNumber: formatDate(),
      };
      let resp = await createAnswerSet(data);
      console.log("responsequestion====>", resp);
      localStorage.setItem("setID", resp.r._id);

      let response = await getAllQuestion();
      if (response.status) {
        // let val = [
        //   {
        //     _id: "67207327062c3a7cd37b8566",
        //     type: 1,
        //     question: "Have you ever been diagnosed with an irregular heartbeat (arrhythmia)?",
        //     option: "Yes, No, Any",
        //     createdAt: "2024-10-29T05:31:19.834Z",
        //     updatedAt: "2024-11-25T07:13:55.480Z",
        //     __v: 0,
        //   },
        //   {
        //     _id: "67207312062c3a7cd37b8562",
        //     type: 1,
        //     question: "Do you often feel dizzy or lightheaded?",
        //     option: "Yes, No, Any",
        //     createdAt: "2024-10-29T05:30:58.336Z",
        //     updatedAt: "2024-11-04T15:14:59.138Z",
        //     __v: 0,
        //   },
        //   {
        //     _id: "6720731206434343434334",
        //     type: 1,
        //     question: "Do you often feel dull ?",
        //     option: "Yes, No, Any",
        //     createdAt: "2024-10-29T05:30:58.336Z",
        //     updatedAt: "2024-11-04T15:14:59.138Z",
        //     __v: 0,
        //   },
        // ];
        let val = response.r;
        // console.log("errorr====> 1", val.r[0]);
        if (localStorage.getItem("questionID")) {
          let question_no = localStorage.getItem("questionID");
          let questionobject = val.find((item) => item._id === question_no);
          setCurrentQuestionIndex(val.indexOf(questionobject));
          setSelectedOption(localStorage.getItem("answer") ? localStorage.getItem("answer") : "");
          console.log("Index of the question:", val.indexOf(questionobject), questionobject, question_no);
        } else {
          localStorage.setItem("questionID", val[0]._id);
        }

        setQuestionSet(val);
      }
    } catch (error) {
      console.log("errorr====>", error);
      Swal.fire({
        icon: "error",
        title: "Health Assessment",
        text: "Sorry, something went wrong. Please try again later.",
      });
    }
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const formatDate = () => {
    const now = new Date();

    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const year = now.getFullYear().toString().slice(-2);

    // Get the time in h:mm AM/PM format
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // 12 AM/PM
    const time = `${hours}:${minutes} ${ampm}`;

    return `${month}/${day}/${year}_${time}`;
  };
  const handleNextQuestion = async () => {
    console.log(currentQuestionIndex <= questionall.length - 1 ? currentQuestionIndex : currentQuestionIndex + 1);
    if (currentQuestionIndex <= questionall.length - 1) {
      if (currentQuestionIndex === questionall.length - 1) {
        // await getResult( localStorage.getItem("setID"))
        localStorage.removeItem("questionID");
        localStorage.removeItem("answer");
        // localStorage.removeItem("setID");
        navigate("/result");
      }
      setCurrentQuestionIndex(currentQuestionIndex < questionall.length - 1 ? currentQuestionIndex + 1 : currentQuestionIndex);
      console.log(questionall[currentQuestionIndex]._id);
      setSelectedOption(null);
      localStorage.setItem("questionID", questionall[currentQuestionIndex]._id);
      localStorage.setItem("answer", selectedOption);
      let data = {
        setID: localStorage.getItem("setID"),
        questionID: questionall[currentQuestionIndex]._id,
        answare: selectedOption,
      };
      try {
        await createAnswer(data);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Health Assessment",
          text: "Sorry, something went wrong. Please try again later.",
        });
      }
    }
  };

  return (
    <div>
      <div>
        <div className="heart-bg">
          <img src="assets/images/heart-bg.svg" alt="heart-bg" />
        </div>
        <div className="card assessment-container">
          <div className="section-header">
            <h2>Health & Risk Assessment Test</h2>
            <div className="divider">
              <span className="line"></span>
              <img src="assets/images/headingheart.svg" alt="Heart Icon" className="icon" />
              <span className="line"></span>
            </div>
          </div>
          {questionall && questionall.length > 0 && (
            <div key={`question-${currentQuestionIndex}`} id={`que-${currentQuestionIndex + 1}`}>
              <form className="form cf">
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${((currentQuestionIndex + 1) / questionall.length) * 100}%` }}></div>
                </div>
                <p className="sub-heading">Answer {questionall.length} simple questions.</p>
                <h2 style={{ fontSize: "30px" }}>{questionall[currentQuestionIndex].question}</h2>
                <div className="plan cf">
                  {questionall[currentQuestionIndex].option.split(",").map((option, idx) => {
                    const trimmedOption = option.trim();
                    return (
                      <div key={`option-${idx + 1}`} className="plan-bx">
                        <input type="radio" name={`radio-${currentQuestionIndex}`} id={`option-${idx + 1}`} value={trimmedOption} checked={selectedOption === trimmedOption} onChange={() => handleOptionChange(trimmedOption)} />
                        <label className={`${trimmedOption.toLowerCase() === "yes" ? "yes-label" : trimmedOption.toLowerCase() === "no" ? "no-label" : "notsure-label"} four col`} htmlFor={`option-${idx + 1}`}>
                          <img src={`assets/images/${trimmedOption.toLowerCase() === "yes" ? "yes-ico" : trimmedOption.toLowerCase() === "no" ? "no-ico" : "notsure-ico"}.svg`} alt="" />
                          <p>{trimmedOption}</p>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </form>
              <div className="btn-group">
                <button className="next-button" onClick={(e) => handleNextQuestion()} disabled={!selectedOption}>
                  Next Question
                </button>
              </div>
              <div className="question-tracker">
                {currentQuestionIndex + 1}/<span>{questionall.length}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HealthAssessmentContainer;
