import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../../Redux/actions.js";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import { loginUser } from "../../slices/action.js";
import Swal from "sweetalert2";
const Login = () => {
  const dispatch = useDispatch();
  // const token = useSelector((state) => state.token);
  let navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userDetail, setUserDetail] = useState({
    email: "",
    password: "",
  });

  // useEffect(() => {

  // }, []);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const updatedUserDetail = {
      ...userDetail,
      [name]: value,
    };

    setUserDetail(updatedUserDetail);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await loginUser(userDetail);
      console.log("resonse====>", response, response.message);
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "Sign In",
          text: response.message,
          width: "500px",
          confirmButtonColor: "#06bcaf",
          customClass: {
            popup: "custom-popup",
            title: "custom-title",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("chek=========>", response);
            sessionStorage.setItem("token", response.r.token);
            // window.location.reload();
            dispatch(setToken(response.r.token));
            navigate("/profile");
            // setTimeout(() => {
            // }, 1000);
          }
        });
      } else if (typeof response.message !== "string" && response.message.isArray()) {
        Swal.fire({
          icon: "error",
          title: "Sign In",
          text: "Validation Failed",
          width: "500px",
          confirmButtonColor: "#06bcaf",
          customClass: {
            popup: "custom-popup",
            title: "custom-title",
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Sign In",
          text: response.message,
          width: "500px",
          confirmButtonColor: "#06bcaf",
          customClass: {
            popup: "custom-popup",
            title: "custom-title",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Sign In",
        text: "Sorry Something Went Wrong.Please Try After Sometime",
        width: "500px",
        confirmButtonColor: "#06bcaf",
        customClass: {
          popup: "custom-popup",
          title: "custom-title",
        },
      });
    }
  };

  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Sign In"} route={"/login"} />
          <div className="card customlogcard">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Hello Again!</h2>
              <p className="subtext">Welcome back, you've been missed!</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input className="form-control" name="email" value={userDetail.email} onChange={handleChange} type="email" placeholder="Email Address*" required />
                  <span className="icon">
                    <img src="assets/images/sms.png" alt="sms" />
                  </span>
                </div>
                <div className="input-group">
                  <input className="form-control" name="password" value={userDetail.password} onChange={handleChange} type={passwordVisible ? "text" : "password"} placeholder="Password*" id="password" required />
                  <span className="icon">
                    <img src="assets/images/lock.png" alt="lock" />
                  </span>
                  <span className="toggle-password" onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                  </span>
                </div>
                {/* <div className="forgot-link">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div> */}
                <input type="submit" className="submitbtn" value="Sign In" />
              </form>
              <p className="signup-link">
                Don't Have An Account? <Link to="/sign-up">Sign Up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footertop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="section-header">
                <h2>Download The App</h2>
                <p>Heart Vantage App available on App Store & Play Store</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="buttoncontainer">
                <a href="#" className="opplatfrom">
                  <img src="assets/images/playstore-wh.svg" alt="Play Store" />
                </a>
                <a href="#" className="opplatfrom">
                  <img src="assets/images/appstore-wh.svg" alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
