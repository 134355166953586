import React from "react";
import { Navigate } from "react-router-dom";

// //Dashboard
// import DashboardAnalytics from "../pages/DashboardAnalytics";
// import DashboardCrm from "../pages/DashboardCrm";
// import DashboardEcommerce from "../pages/DashboardEcommerce";

// import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

// //APi Key
// import APIKey from "../pages/APIKey/index";

// //login
import Login from "../pages/Authentication/Login";
import Signup from "../pages/Authentication/Signup";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import PasswordReset from "../pages/Authentication/PasswordReset";
import CreateNewPassword from "../pages/Authentication/CreateNewPassword";
import Home from "../pages/Common/Home.jsx";
import AboutUs from "../pages/Common/AboutUs.jsx";
import ContactUs from "../pages/Common/ContactUs.jsx";
import PrivacyPolicy from "../pages/Common/PrivacyPolicy.jsx";
import TermsAndCondition from "../pages/Common/TermsAndCondition.jsx";
import HealthAssessment from "../pages/Components/HealthAssessment.jsx";
import Result from "../pages/Components/Result.jsx";
import HeartAttack from "../pages/Components/HeartAttack.jsx";
import Profile from "../pages/Components/Profile.jsx";
import LigalDisclaimer from "../pages/Common/LegalDisclaimer.jsx";
const authProtectedRoutes = [
  { path: "/profile", component: <Profile /> },
  { path: "/result", component: <Result /> },
  { path: "/home", component: <Home /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/legal-Disclaimer", component: <LigalDisclaimer /> },
  { path: "/terms-condition", component: <TermsAndCondition /> },
  { path: "/health-assessment", component: <HealthAssessment /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/profile" />,
  },
  // { path: "*", component: <Navigate to="/profile" /> },
];

const publicRoutes = [
  // { path: "/logout", component: <Logout /> },
  //authentication pages
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <Signup /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/create-new-password", component: <CreateNewPassword /> },
  { path: "/reset-password", component: <PasswordReset /> },
  //hero pages
  { path: "/home", component: <Home /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/contact-us", component: <ContactUs /> },
  //content pages

  { path: "/profile", component: <Profile /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-condition", component: <TermsAndCondition /> },
  { path: "/legal-Disclaimer", component: <LigalDisclaimer /> },
  { path: "/heart-attack", component: <HeartAttack /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
  // { path: "*", component: <Navigate to="/home" /> },
];

export { authProtectedRoutes, publicRoutes };
