import Swal from "sweetalert2";
const FooterTop = () => {
  const handlePopUp = (popup) => {
    Swal.fire({
      icon: "success",
      title: "Exciting News!",
      text: `Our app has just been submitted to the ${popup}. We’re in the final stages of review, and it will be available for download very soon! Stay tuned for updates, and thank you for your support as we bring HeartVantage mobile version to life!`,
      width: "500px",
      confirmButtonColor: "#06bcaf",
      customClass: {
        popup: "custom-popup",
        title: "custom-title",
      },
    });
  };
  return (
    <div class="footertop">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="section-header">
              <h2>Download The App</h2>
              <p>Heart Vantage App available App store & Play Store</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="buttoncontainer">
              <a onClick={(e) => handlePopUp("Google Play Store")} class="opplatfrom">
                <img src="assets/images/playstore-wh.svg" alt="" />
              </a>
              <a onClick={(e) => handlePopUp("Apple App Store")} class="opplatfrom">
                <img src="assets/images/appstore-wh.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTop;
