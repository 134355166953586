// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Nav from "../Common/Nav";
// import Footer from "../Common/Footer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import FooterTop from "../Common/FooterTop";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import HealthAssessmentContainer from "../Common/HealthAssessmentContainer";
const HealthAssessment = () => {
  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Profile"} route={"/profile"} />

          <HealthAssessmentContainer />
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default HealthAssessment;
