import React from 'react';
import { Link } from 'react-router-dom';
// import { Col, Row } from 'reactstrap';

const BreadCrumb = ({ routeHome, PageTitle , route}) => {
    return (
        <React.Fragment>
            <div className="inner-banner-txt">
            <ul>
              <li>
                <Link to={routeHome}>Home</Link>
              </li>
              <li>
                <Link to={route} className="active">
                  {PageTitle}
                </Link>
              </li>
            </ul>
          </div>
        </React.Fragment>
    );
};

export default BreadCrumb;