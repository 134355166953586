// import React, { useState } from "react";
import FooterTop from "./FooterTop";
import ScreenPlay from "./ScreenPlay";
const LegalDisclaimer = () => {
  return (
    <div>
      <div class="loginnWrappermain loginnWrappermain_content">
        <div class="container">
          <div class="inner-banner-txt">
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" class="active">
                  Legal Disclaimer
                </a>
              </li>
            </ul>
          </div>
          <div class="card customlogcard">
            <div class="form-section contentpagewrapper">
              <h2>Legal Disclaimer</h2>

              <h3>Medical Disclaimer</h3>

              <p>
                The information provided on this website/mobile app, including
                the recommendations regarding the use of Disprin (Aspirin),
                Clopidogrel, and Atorvastatin during a suspected heart attack,
                is for informational purposes only. This information is not a
                substitute for professional medical advice, diagnosis, or
                treatment.
              </p>
              <p>
                If you suspect a heart attack, you should immediately contact
                emergency medical services (e.g., 911) and seek the guidance of
                a licensed healthcare professional. Administering these
                medications without proper medical supervision can lead to
                adverse effects, complications, or even life-threatening
                conditions, especially in individuals with pre-existing
                conditions, allergies, or those on other medications.
              </p>

              <h3>Important Considerations</h3>
              <p>
                Consult a Professional: Always consult a qualified healthcare
                provider or emergency physician before administering any
                medication during a suspected heart attack.
              </p>

              <p>
                Individual Risk Factors: These medications may not be
                appropriate for individuals with certain medical conditions,
                such as bleeding disorders, liver or kidney disease, or
                allergies to any of the listed medications.
              </p>

              <p>
                Drug Interactions: Combining these medications with other
                prescriptions, over-the-counter drugs, or supplements may cause
                harmful interactions.
              </p>

              <p>
                Dosage and Administration: The dosages listed are general
                recommendations. Only a healthcare professional can determine
                the correct dosage and suitability based on individual health
                factors.
              </p>
              <p>
                Dosage and Administration: The dosages listed are general
                recommendations. Only a healthcare professional can determine
                the correct dosage and suitability based on individual health
                factors.
              </p>

              <h3>Limitations of Liability</h3>
              <p>
                The creators, owners, and operators of this website/mobile app
                are not healthcare providers and are not liable for any adverse
                outcomes, injuries, or complications arising from the use or
                misuse of the information provided here. By accessing this
                information, you agree to assume full responsibility for your
                actions and release the website/mobile app and its operators
                from any claims or liabilities.
              </p>

              <h3>Acknowledgement of Terms</h3>
              <p>By using this website/mobile app, you confirm that you:</p>
              <p>Have read and understood this disclaimer.</p>
              <p>
                Acknowledge that the information provided is not personalized
                medical advice. Accept full responsibility for any actions taken
                based on this information.
              </p>
              <p>
                For accurate medical advice, always consult a qualified
                healthcare professional. If you suspect a heart attack, seek
                immediate emergency assistance from medical professionals.
              </p>
            </div>
          </div>
        </div>
      </div>

      <FooterTop />
    </div>
  );
};

export default LegalDisclaimer;
