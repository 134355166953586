// import React, { useState } from "react";
import FooterTop from "./FooterTop";
import ScreenPlay from "./ScreenPlay";
const TermsAndCondition = () => {
  return (
    <div>
      <div class="loginnWrappermain loginnWrappermain_content">
        <div class="container">
          <div class="inner-banner-txt">
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" class="active">
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
          <div class="card customlogcard">
            <div class="form-section contentpagewrapper">
              <h2>Terms and Conditions</h2>
              <p>
                <strong>Effective Date:</strong> 27th November 2024
              </p>

              <p>
                Welcome to HeartVantage! These Terms and Conditions ("Terms") govern your access and use of the HeartVantage mobile application ("App") and website ("Website"), including all features, services, and content provided. By using the App or Website, you agree to these Terms. If you do
                not agree, please discontinue use immediately.
              </p>

              <h3>Use of Services</h3>
              <ul>
                <li>
                  <strong>Eligibility:</strong>You must be at least 18 years old or have parental consent to use HeartVantage.
                </li>
                <li>
                  <strong>Purpose:</strong> HeartVantage is a health information platform designed to provide heart-health assessments and recommendations. It is not a substitute for professional medical advice, diagnosis, or treatment
                </li>
                <li>
                  <strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities under your account.
                </li>
              </ul>

              <h3>Medical Disclaimer</h3>
              <ul>
                <li>HeartVantage provides general health information and AI-generated recommendations.</li>
                <li>The App and Website do not constitute medical advice or replace consultation with a licensed healthcare professional. Always seek the advice of a qualified physician for health concerns.</li>
                <li>In the event of a medical emergency, contact emergency services immediately.</li>
              </ul>

              <h3>User Obligations</h3>
              <ul>
                <li>You agree to provide accurate and complete information for assessments and refrain from misusing the services.</li>
                <li>You may not use the App or Website for illegal or unauthorized purposes.</li>
                <li>Any attempt to interfere with or disrupt the functionality of HeartVantage, including reverse engineering or exploiting vulnerabilities, is strictly prohibited.</li>
              </ul>

              <h3>Intellectual Property</h3>
              <ul>
                <li>All content, features, and materials on HeartVantage, including text, graphics, logos, and software, are the intellectual property of HeartVantage and are protected by applicable copyright, trademark, and other intellectual property laws.</li>
                <li>You may not reproduce, distribute, or modify any content without prior written permission from HeartVantage.</li>
              </ul>

              <h3>Privacy and Data Security</h3>
              <ul>
                <li>Your use of HeartVantage is governed by our Privacy Policy. By using the App or Website, you consent to the collection, storage, and use of your information as outlined in the Privacy Policy.</li>
                <li>While HeartVantage implements robust security measures, we do not guarantee the absolute security of data transmitted via the App or Website.</li>
              </ul>

              <h3>Limitations of Liability</h3>
              <ul>
                <li>HeartVantage is provided "as is" without any warranties, express or implied, including fitness for a particular purpose or non-infringement</li>
                <li>HeartVantage is not liable for any damages resulting from the use or inability to use the App or Website, including direct, indirect, incidental, or consequential damages.</li>
                <li>You assume all responsibility for decisions made based on the information provided by HeartVantage.</li>
              </ul>

              <h3>Third-Party Services and Links</h3>
              <ul>
                <li>The App and Website may include links to third-party services or integrate with third-party tools. HeartVantage is not responsible for the content, privacy practices, or functionality of third-party services.</li>
              </ul>

              <h3>Modifications to Terms and Services</h3>
              <ul>
                <li>HeartVantage reserves the right to update or modify these Terms at any time. Significant changes will be communicated via the App or Website. Continued use of the services constitutes acceptance of the revised Terms.</li>
                <li>HeartVantage may modify or discontinue features or services without prior notice.</li>
              </ul>

              <h3>Governing Law and Dispute Resolution</h3>
              <ul>
                <li>These Terms are governed by the laws of [Your Country/Region], without regard to conflict of laws principles.</li>
                <li>Any disputes arising from these Terms will be resolved through arbitration or in the courts of [Your Country/Region], unless otherwise required by local law.</li>
              </ul>

              <h3>Termination</h3>
              <ul>
                <li>HeartVantage reserves the right to terminate or suspend your access to the App or Website for violation of these Terms or any illegal or unauthorized use.</li>
              </ul>

              <h3>Contact Information</h3>
              <ul>
                <li>
                  If you have any questions or concerns regarding these Terms, please contact us at:
                  <br />
                  Email: heartvantage@gmail
                  <br />
                  Address: 5 Virginia Drive, Whitby, ON L1R 2Y1
                </li>
              </ul>

              <p>By using HeartVantage, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>
            </div>
          </div>
        </div>
      </div>

      <FooterTop />
    </div>
  );
};

export default TermsAndCondition;
