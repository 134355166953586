import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-owl-carousel2/lib/styles.css";
import FooterTop from "./FooterTop";
import ScreenPlay from "./ScreenPlay";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { setToken } from "../../Redux/actions.js";
import "react-tooltip/dist/react-tooltip.css";
export default function Home() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showModal, setModal] = useState(false);
  const [ready, setReady] = useState(false);
  const handleAssigment = () => {
    // e.preventDefault();
    let token = sessionStorage.getItem("token");

    if (!token) {
      setModal(!showModal);
    } else {
      navigate("/health-assessment");
      setModal(false);
    }
  };
  const closeModal = () => {
    navigate("/login");
  };
  useEffect(() => {
    CheckToken();
    setReady(true);
  }, []);
  async function CheckToken() {
    if (!sessionStorage.getItem("token")) {
      dispatch(setToken(null));
    }
  }
  const handlePopUp = (popup) => {
    Swal.fire({
      icon: "success",
      title: "Exciting News!",
      text: `Our app has just been submitted to the ${popup}. We’re in the final stages of review, and it will be available for download very soon! Stay tuned for updates, and thank you for your support as we bring HeartVantage mobile version to life!`,
      width: "500px",
      confirmButtonColor: "#06bcaf",
      customClass: {
        popup: "custom-popup",
        title: "custom-title",
      },
    });
  };
  if (!ready) {
    return;
  }
  return (
    <div>
      {/* <!-- Home banner --> */}
      <div className="home-banner">
        <div className="home-banner-img">
          <img className="d-none d-md-block" src="assets/images/homebanner.png" alt="home-banner-img" />
          <img className="d-block d-md-none" src="assets/images/mobile-banner.png" alt="home-banner-img" />
        </div>
        <div className="home-banner-text-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-8 col-md-9">
                <div className="home-banner-text wow animate__fadeInUp" data-wow-duration="1s">
                  <h1>Saving families, preventing one heart attack at a time.</h1>
                  <p>
                    Your health is the foundation of everything you do—an investment in a better, healthier tomorrow. Every effort today paves the way for a life of vitality, not regret.
                    <span>
                      <FontAwesomeIcon icon={faInfoCircle} data-tooltip-id="my-tooltip-1" style={{ color: "#999" }} />

                      <ReactTooltip id="my-tooltip-1" place="top" effect="solid">
                        <div
                          className="d-block"
                          style={{
                            maxWidth: "500px",
                            height: "auto",
                            whiteSpace: "normal",
                            overflow: "visible",
                            wordWrap: "break-word",
                          }}
                        >
                          <div className="text-truncate">Did You Know?</div>
                          <div
                            className="text-truncate"
                            style={{
                              whiteSpace: "normal",
                              overflow: "visible",
                              textOverflow: "clip",
                            }}
                          >
                            18 million people die of Cardiovascular Diseases each year across the world; that’s approximately 35 deaths every single minute. Over half of these deaths are preventable; however, health care systems often fail to provide timely interventions and effective preventive
                            care.
                          </div>
                        </div>
                      </ReactTooltip>
                    </span>
                  </p>
                  <div className="btn-group">
                    <button onClick={(e) => handlePopUp("Google Play Store")} className="opplatfrom">
                      <img src="assets/images/playstore.svg" alt="" />
                    </button>
                    <button onClick={(e) => handlePopUp("Apple App Store")} className="opplatfrom">
                      <img src="assets/images/appstore.svg" alt="" />
                    </button>
                  </div>
                  <div>
                    <button onClick={handleAssigment} className="custombtn btn-2 hover-filled-slide-down">
                      <span>Heart Assessment</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Heart Assessment
                </h5>
                <button type="button" style={{ border: "unset" }} className="close" onClick={(e) => handleAssigment(0)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">You Need to Logged In.</div>
              <div className="modal-footer">
                <button type="button" className="btn rounded-pill text-white" style={{ backgroundColor: "#06bcaf", padding: "10px 20px" }} onClick={(e) => closeModal(0)}>
                  Log In
                </button>
                <button type="button" className="btn rounded-pill text-white" style={{ backgroundColor: "#2a4c8b", padding: "10px 20px" }} onClick={(e) => handleAssigment(0)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <section className="lifestylesec">
        <img className="lefthalf" src="assets/images/lefthalf.png" alt="shadow" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="section-header">
                <h2>Lifestyle and Diet</h2>
                <div className="divider">
                  <span className="line"></span>
                  <img src="assets/images/headingheart.svg" alt="Heart Icon" className="icon" />
                  <span className="line"></span>
                </div>
                <p>
                  At HeartVantage we believe that the key to maintaining a healthy heart involves more than just traditional treatments. We have created a short list of effective lifestyle changes you can make to ensure the well-being of your heart. Explore our extensive resources and begin your
                  journey towards better heart health down below.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card lifestylecard">
                    <div className="icon">
                      <img src="assets/images/lifestyle-ico1.svg" alt="Active Lifestyle" />
                    </div>
                    <h3>Active Lifestyle</h3>
                    <p>Exercise and engage in 30 minutes of physical activity daily.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card lifestylecard">
                    <div className="icon">
                      <img src="assets/images/lifestyle-ico2.svg" alt="Manage Stress" />
                    </div>
                    <h3>Manage Your Stress</h3>
                    <p>Long-Term Stress in your daily life can take a toll on your heart health.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card lifestylecard">
                    <div className="icon">
                      <img
                        src="assets/images/lifestyle-ico3.svg"
                        alt="Reduce Nicotine,
                  Tobacco & Alcohol"
                      />
                    </div>
                    <h3>Reduce Nicotine, Tobacco & Alcohol</h3>
                    <p>The vitality of your heart is broken from the effects of Nicotine & Alcohol usage.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="card lifestylecard">
                    <div className="icon">
                      <img
                        src="assets/images/lifestyle-ico4.svg"
                        alt="Ancient Yogic Practices 
                  for Heart"
                      />
                    </div>
                    <h3>Ancient Yogic Practices for Heart</h3>
                    <p>Engage in guided yogic exercises for a healthy heart.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec2">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <figure>
                <img src="assets/images/support-image-one.png" alt="" />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="sec2right">
                <div className="section-header">
                  <h2>What is Heart Attack?</h2>
                  <p>
                    A heart attack occurs when blood and oxygen supply to a certain part of the heart is cut off. If this occurs the cells in that part of the heart begin to die at a rapid rate. Heart attack can result in death and often causes irreversible complications such as weakened pumping
                    ability of the heart, damage to the valves of the heart and arrhythmias. These complications can result in future heart failure, highlighting the clear need for preventive care.
                  </p>
                </div>
                <h5>Symptoms of a Heart Attack</h5>
                <ul>
                  <li>Sharp chest pain and discomfort.</li>
                  <li>Nausea.</li>
                  <li>Vomiting.</li>
                  <li>Dizziness or Lightheadedness.</li>
                  <li>Shortness of breath.</li>
                  <li>Cold sweating.</li>
                  <li>Pain in the jaw, neck, back, arms or shoulders.</li>
                </ul>
                <button onClick={handleAssigment} className="custombtn btn-2 hover-filled-slide-down">
                  <span>Heart Assessment</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <img className="bigheart" src="assets/images/big-heart.svg" alt="" />
      </section>
      <section className="featuresection">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="featuresectionleft">
                <div className="section-header">
                  <h2>My App Feature</h2>
                  <p>The mobile version of this app will have following features:</p>
                </div>
                <ul>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-one.svg" alt="" />
                    </span>
                    Pre-Test Results
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-two.svg" alt="" />
                    </span>
                    Re-Test Heart Assessment
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-three.svg" alt="" />
                    </span>
                    Heart Attack Info
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-four.svg" alt="" />
                    </span>
                    Lifestyle & Diet Info
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-five.svg" alt="" />
                    </span>
                    Health Diagnostic
                  </li>
                  <li>
                    <span>
                      <img src="assets/images/app-feature-six.svg" alt="" />
                    </span>
                    Help & Support
                  </li>
                </ul>
                <button onClick={handleAssigment} className="custombtn btn-1 hover-filled-slide-down">
                  <span>Heart Assessment</span>
                </button>
              </div>
            </div>
            <div className="col-md-5">
              <figure>
                <img src="assets/images/support-image-two.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="accordionarea">
        <img className="righthalf" src="assets/images/righthalf.png" alt="shadow" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="faqcontainer">
                <div className="row">
                  <div className="col-md-4">
                    <figure>
                      <img src="assets/images/heatbeat.png" alt="heart" />
                    </figure>
                  </div>
                  <div className="col-md-8">
                    <div className="section-header">
                      <h2>Health Diagnostic & Medications</h2>
                    </div>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            CT Coronary Scan
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            This diagnostic test will determine the blockage and plaque build-up in your arteries. It is non-invasive and painless as it identifies any blockages in your arteries. Too much blockage in any major artery can cause a blood clot to form which blocks the arteries resulting
                            in a heart attack. Complete this test to identify your risk of heart attack.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            ECG/EKG
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            This diagnostic test monitors the heartbeat by recording electrical signals in the heart. It is non-invasive and painless; however, it is still very effective in diagnosing heart arrhythmias and heart attack(exact type) which helps doctors determine the most effective
                            treatment in your case.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Blood Test
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            This test can measure your cholesterol and hemoglobin levels. Although it is slightly painful, the test is still relatively quick and easy to get through while being effective to determine heart attack risk. High cholesterol levels often result in fat deposits in your
                            blood vessels which can cause blockage and heart attacks. Additionally, irregular hemoglobin levels are another indicator for heart attack risk which is measured in this test.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            EECP Treatment
                          </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                          <div className="accordion-body">EECP Treatment is a treatment which helps to improve blood flow in your body by applying pressure to lower limbs. Through EECP treatment patients experience a circulation improvement of 70% on average.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-11 book-appointment-wrapper">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="section-header">
                    <h2 className="text-start">Book Appointment</h2>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <ul>
                    <li>
                      <a href="https://theheartclinic.ca/#booka" target="_blank">
                        The Heart Clinic
                      </a>
                    </li>
                    <li>
                      <a href="https://ontarioheartcenter.ca/book-appointment/" target="_blank">
                        The Ontario Heart Center
                      </a>
                    </li>
                    <li>
                      <a href="https://www.oneheartcare.ca/contact.html" target="_blank">
                        One Heart Care
                      </a>
                    </li>
                    <li>
                      <a href="https://canadadiagnostics.ca/services/computed-tomography-ct/preventative-health-scans-heart-scan/" target="_blank">
                        {" "}
                        Canada Diagnostics
                      </a>
                    </li>
                    <li>
                      <a href="https://x-ray.ca/services/computed-tomography/angiogram-ct/" target="_blank">
                        {" "}
                        X-Ray Services Center
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="section-header">
                <h2>My Mission</h2>
                <div className="divider">
                  <span className="line"></span>
                  <img src="assets/images/headingheart.svg" alt="Heart Icon" className="icon" />
                  <span className="line"></span>
                </div>
                <p>
                  My Mission at HeartVantage is to empower individuals to assume responsibility for their own heart health through the provision of readily available, comprehensive and tailored information. HeartVantage is dedicated to encouraging good heart health in the community, preventing heart
                  diseases and creating an informed and proactive community of users. My goal is to make sure that everyone values cardiovascular well-being by offering dependable insights & practical guidance.
                </p>
              </div>
              <div className="buttoncontainer">
                <button onClick={(e) => handlePopUp("Google Play Store")} className="opplatfrom">
                  <img src="assets/images/playstore.svg" alt="" />
                </button>
                <button onClick={(e) => handlePopUp("Apple App Store")} className="opplatfrom">
                  <img src="assets/images/appstore.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <img className="portrait" src="assets/images/portrait-smiling.png" alt="" />
      </section>
      <section className="sec2 aboutsec">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <figure>
                <img src="assets/images/support-image-five.png" alt="" />
              </figure>
            </div>
            <div className="col-md-7">
              <div className="sec2right">
                <div className="section-header">
                  <h2>About Me</h2>
                  <p>
                    Hi, I'm Rudransh, and my journey with heart health began in 2022 when I lost my grandmother to a sudden heart attack. Her passing left a deep void in my life and raised lingering questions: How could this have been prevented? Despite regular check-ups, an undiagnosed artery
                    blockage claimed her life.
                  </p>
                </div>
                <h5>My Skill</h5>
                <ul>
                  <li>UI Design in Figma</li>
                  <li>Frontend Design in HTML5 & CSS</li>
                  <li>Front-end Development in React</li>
                  <li>MongoDB, Bun Hono</li>
                  <li>Mobile App Technology Flutter</li>
                </ul>
                <div>
                  <a href="/about-us" className="custombtn btn-1 hover-filled-slide-down">
                    <span>Read More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="righthalf" src="assets/images/rightnd.png" alt="" />
      </section>

      <ScreenPlay />

      <FooterTop />
    </div>
  );
}
