// import React, { useState } from "react";
const ContactUs = () => {
  return (
    <div>
      <div className="inner-banner">
        <img className="corner-shadow" src="assets/images/cornershadow.png" alt="" />
        <div className="inner-banner-text-area">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="inner-banner-txt" data-wow-duration="1s">
                  <ul>
                    <li>
                      <a href="/home">Home</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="active">
                        Contact Me
                      </a>
                    </li>
                  </ul>
                  <h1>Contact Me</h1>
                </div>
              </div>
              <div className="col-md-6">
                <div className="inner-banner-img text-center text-lg-end">
                  <img src="assets/images/contact-banner-Image.png" alt="inner-banner-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contavTmaiin">
        <div className="container d-flex justify-content-center align-items-center ">
          <div className="card contactCard border-0">
            <div className="section-header">
              <h2>Get In Touch with Us</h2>
              <div className="divider">
                <span className="line"></span>
                <img src="assets/images/headingheart.svg" alt="Heart Icon" className="icon" />
                <span className="line"></span>
              </div>
            </div>
            <div className="text-center mb-3">
              <i className="bi bi-gem" style={{ fontSize: "24px", color: "#17a2b8" }}></i>
            </div>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <input type="text" className="form-control form-control-lg rounded-pill" placeholder="First Name" />
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control form-control-lg rounded-pill" placeholder="Last Name" />
                </div>
                <div className="col-md-6">
                  <input type="email" className="form-control form-control-lg rounded-pill" placeholder="Email" />
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control form-control-lg rounded-pill" placeholder="Phone Number" />
                </div>
                <div className="col-12">
                  <textarea className="form-control form-control-lg rounded-3" rows="5" placeholder="Your Message"></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <input type="submit" className="submitbtn" value="Contact Me" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
