import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import { regisTration } from "../../slices/action.js";
import Swal from "sweetalert2";
const SignUp = () => {
  let navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [userDetail, setUserDetail] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const togglePasswordVisibility = (field) => {
    console.log("filed====>", field);
    if (field === "password") {
      setPasswordVisible(!passwordVisible);
    } else if (field === "confirm_password") {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const updatedUserDetail = {
      ...userDetail,
      [name]: value,
    };

    if ((name === "confirm_password" && value !== updatedUserDetail.password) || (name === "password" && value !== updatedUserDetail.confirm_password)) {
      setErrorStatus("Password and confirm password must match");
    } else {
      setErrorStatus("");
    }

    setUserDetail(updatedUserDetail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      userDetail.phone = Number(userDetail.phone);
      const response = await regisTration(userDetail);
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "Signup",
          text: response.message,
        }).then((result) => {
          if (result.isConfirmed) {
            // <Navigate to="/login" />;
            navigate("/login");
          }
        });
      } else if (typeof response.message !== "string" && response.message.isArray()) {
        Swal.fire({
          icon: "error",
          title: "Signup",
          text: "Validation Failed",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Signup",
          text: response.message,
        });
      }

      console.log("userDetail===>", response);
    } catch (error) {
      console.error("Registration failed: ", error);
      Swal.fire({
        icon: "error",
        title: "Signup",
        text: "Sorry Something Went Wrong.Please Try After Sometime",
      });
    }
  };

  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Sign Up"} route={"/sign-up"} />

          <div className="card customlogcard signup">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Create Account</h2>
              <p className="subtext">Create your account in just a few steps.</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input className="form-control" name="name" type="text" placeholder="Full Name*" onChange={handleChange} value={userDetail.full_name} required />
                  <span className="icon">
                    <img src="assets/images/user.png" alt="user" />
                  </span>
                </div>
                <div className="input-group">
                  <input className="form-control" name="email" type="email" onChange={handleChange} value={userDetail.email} placeholder="Email Address*" required />
                  <span className="icon">
                    <img src="assets/images/sms.png" alt="sms" />
                  </span>
                </div>
                <div className="input-group">
                  <input className="form-control" name="phone" type="number" min={0} onChange={handleChange} value={userDetail.phone} placeholder="Phone Number*" required />
                  <span className="icon">
                    <img src="assets/images/sms.png" alt="sms" />
                  </span>
                </div>
                <div className="input-group">
                  <input className="form-control" name="password" onChange={handleChange} value={userDetail.password} type={passwordVisible ? "text" : "password"} placeholder="Password*" id="password" required />
                  <span className="icon">
                    <img src="assets/images/lock.png" alt="lock" />
                  </span>
                  <span className="toggle-password" onClick={() => togglePasswordVisibility("password")}>
                    <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                  </span>
                </div>
                <div className="input-group">
                  <input type={confirmPasswordVisible ? "text" : "password"} value={userDetail.confirm_password} onChange={handleChange} name="confirm_password" className="form-control" id="confirm_password" placeholder="Confirm Password*" required />
                  <span className="icon">
                    <img src="assets/images/lock.png" alt="lock" />
                  </span>
                  <span className="toggle-password" onClick={() => togglePasswordVisibility("confirm_password")}>
                    <FontAwesomeIcon icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                  </span>
                </div>
                <p style={{ position: "relative", bottom: "20px", color: "red" }}>{errorStatus}</p>
                <input type="submit" className="submitbtn" value="Sign Up" />
              </form>
              <p className="signup-link">
                Already a user? <Link to="/login">Sign In</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footertop">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="section-header">
                <h2>Download The App</h2>
                <p>Heart Vantage App available on App Store & Play Store</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="buttoncontainer">
                <a href="#" className="opplatfrom">
                  <img src="assets/images/playstore-wh.svg" alt="Play Store" />
                </a>
                <a href="#" className="opplatfrom">
                  <img src="assets/images/appstore-wh.svg" alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
