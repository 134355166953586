// Index.js
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../Redux/actions";  // Import setToken action
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import Nav from "../pages/Common/Nav.jsx";
import Footer from "../pages/Common/Footer.jsx";

const Index = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token); // Access token from Redux store
 console.log("token=====>", token)
  useEffect(() => {
    const savedToken = sessionStorage.getItem("token");
    if (savedToken) {
      dispatch(setToken(savedToken)); // Set token in Redux store if it exists in sessionStorage
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <Routes>
        {/* Authenticated routes */}
        {token &&
          authProtectedRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <>
                  <Nav tabTitle={"Profile"} link={"/profile"} />
                  {route.component}
                  <Footer />
                </>
              }
            />
          ))}

        {/* Public routes */}
        {!token &&
          publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <>
                  <Nav tabTitle={"Log In"} link={"/login"} />
                  {route.component}
                  <Footer />
                </>
              }
            />
          ))}

        {/* Redirect to profile if authenticated, or to login if not */}
        <Route
          path="*"
          element={<Navigate to={token ? "/profile" : "/login"} />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default Index;
