import React, { useState, useEffect } from "react";
import FooterTop from "../Common/FooterTop";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import { useNavigate } from "react-router";
import HealthAssessmentContainer from "../Common/HealthAssessmentContainer";
import { getAllSet, getQNSet, getAllQuestion, profileDetail } from "../../slices/action.js";
const Profile = () => {
  let navigate = useNavigate();
  const [historyData, setHistoryData] = useState([]);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [activeTab, setActiveTab] = useState("personalDetails");
  const [showModal, setShowModal] = useState(false);
  const [resultDetail, setResultDetail] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!sessionStorage.getItem("token")) {
      navigate("/home");
      return;
    }
    setReady(true);
  }, []);
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        let resp = await getAllSet();
        console.log("res=====>", resp);
        if (resp.status) {
          setHistoryData(resp.r);
        }
      } catch (error) {}
    };

    const fetchUserDetails = async () => {
      let res = await profileDetail();
      const mockDetails = {
        name: res.r[0].name,
        email: res.r[0].email,
      };
      setUserDetails(mockDetails);
    };

    fetchHistory();
    fetchUserDetails();
  }, []);
  const handleResult = async (data, id) => {
    console.log("res========================>qnnnnnnn", data, historyData);
    if (data) {
      try {
        // let res = await getQNSet({ setID: data });
        //  setResultDetail({ setID: data });
        data[0] = { setName: id };
        console.log("data=======>", data);
        setQuestionAnswers(data);
      } catch (error) {}
    }
    setShowResultModal(!showResultModal);
  };
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "healthAssessment") {
      setShowModal(true);
    }
    if (tabName === "logout") {
      sessionStorage.removeItem("token");
      // window.location.reload();
      navigate("/home");
    }
  };
  const closeModal = (data) => {
    setShowModal(false);
  };
  const handleRetest = async (data) => {
    let res = await getAllQuestion();
    localStorage.setItem("setID", data._id);
    console.log("testRest====", data._id, res.r[0]._id);
    localStorage.setItem("questionID", res.r[0]._id);
    localStorage.removeItem("answer");
    navigate("/health-assessment");
  };
  if (!ready) {
    return;
  }
  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Profile"} route={"/profile"} />

          <div className="card customlogcard profileon">
            <div className="form-section">
              <h2>My Profile</h2>
              {/* <h2>Welcome, {userDetails.name}</h2> */}
              <p className="subtext">
                <a href={`mailto:${userDetails.email}`}>{userDetails.email}</a>
              </p>

              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {/* <button className={`nav-link ${activeTab === "personalDetails" ? "active" : ""}`} onClick={() => handleTabChange("personalDetails")}>
                    Edit Personal Details
                  </button>
                  <button className={`nav-link ${activeTab === "changePassword" ? "active" : ""}`} onClick={() => handleTabChange("changePassword")}>
                    Change Password
                  </button> */}
                  <button className={`nav-link ${activeTab === "personalDetails" ? "active" : ""}`} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => handleTabChange("personalDetails")}>
                    Heart Assessment History
                  </button>
                  <button className={`nav-link ${activeTab === "healthAssessment" ? "active" : ""}`} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => handleTabChange("healthAssessment")}>
                    Heart Assessment
                  </button>
                  <button className={`nav-link ${activeTab === "logout" ? "active" : ""}`} data-toggle="modal" data-target="#exampleModalCenter" onClick={() => handleTabChange("logout")}>
                    Log Out
                  </button>
                </div>
              </nav>

              <div className="tab-content" id="nav-tabContent">
                {activeTab === "personalDetails" && (
                  <div className="tab-pane fade active show">
                    <div className="history">
                      <h2>Pre-Test Results History</h2>
                      {historyData.length > 0 ? (
                        historyData.map((item, index) => (
                          <div className="history-item" key={index}>
                            <div className="item-details">
                              <span className="icon-calendar">
                                <img className="me-2" src="assets/images/canlender.svg" alt="" />
                                {item.setNumber}
                              </span>
                            </div>
                            <div className="item-actions">
                              {console.log("items===============>", item)}
                              {item?.userQuestion.length > 1 ? (
                                <button className="custombtn btn-2 hover-filled-slide-down" onClick={(e) => handleResult(item.userQuestion, item.setNumber)}>
                                  <span>Results</span>
                                </button>
                              ) : (
                                <button className="custombtn btn-2 hover-filled-slide-down disabled" disabled>
                                  <span>Results Unavailable</span>
                                </button>
                              )}
                              <button onClick={(e) => handleRetest(item)} className="custombtn btn-1 hover-filled-slide-down">
                                <span>Re-Test</span>
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No test results available.</p>
                      )}
                    </div>
                    {showResultModal && (
                      <div className="modal fade show hover-filled-slide-down" tabIndex="-1" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                        <div className="modal-dialog modal-xl">
                          <div className="modal-content">
                            <div className="modal-header text-center">
                              <h3 id="preTestModalLabel">Pre-Test Results History</h3>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleResult(false)}></button>
                            </div>
                            <div className="modal-body">
                              <div className="text-center mb-4 questiontop">
                                <span className="me-3">
                                  <img className="me-2" src="assets/images/canlender.svg" alt="" />
                                  {console.log("name=========>", questionAnswers)}
                                  {questionAnswers[0].setName}
                                </span>
                              </div>
                              <ul className="question-list">
                                {questionAnswers.slice(1).map((item, index) => {
                                  // if (item.role != "user") return;
                                  const [question, answer] = item.content.split(/ Answare:|\?Answare:/);
                                  return (
                                    <li className="question-item" key={index}>
                                      <div className="question-text">
                                        <span className="question-number">{index + 1}</span>
                                        {question.replace("Question:", "").trim()}
                                      </div>
                                      <div className="answer-buttons">
                                        <button className={`btn ${answer.trim() === "Yes" ? "btn-yes" : answer.trim() === "No" ? "btn-no" : "btn-not-sure"}`}>{answer.trim()}</button>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {activeTab === "changePassword" && (
                  <div className="tab-pane fade active show">
                    <p>
                      <strong>This is some placeholder content the Profile tab's associated content.</strong>
                      Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other <code>.nav</code>-powered navigation.
                    </p>
                  </div>
                )}

                {activeTab === "healthAssessment" && (
                  <div className="tab-pane fade active show">
                    <HealthAssessmentContainer />
                  </div>
                )}

                {showModal && (
                  <div className="modal fade show" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLongTitle">
                            Health Assessment
                          </h5>
                          <button type="button" className="close" style={{ border: "unset", width: "20px" }} onClick={(e) => closeModal(0)} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">This result is generated by AI and is not intended to serve as a medical diagnosis. If you are experiencing any of the symptoms mentioned, it is strongly recommended that you consult a qualified medical professional immediately.</div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={(e) => closeModal(0)}>
                            ok
                          </button>
                          {/* <button type="button" className="btn btn-primary" onClick={closeModal(1)}>
                            Save changes
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default Profile;
