import React, { useState } from "react";
import FooterTop from "../Common/FooterTop";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forgotPasswordVerify } from "../../slices/action.js";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
const CreateNewPassword = () => {
  let navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // Toggle visibility for password and confirm password fields
  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setPasswordVisible(!passwordVisible);
    } else if (field === "confirmPassword") {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirm-password").value;

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    try {
      const response = await forgotPasswordVerify({
        otp: sessionStorage.getItem("otp"),
        email: sessionStorage.getItem("email"),
        newPassword: "ABX",
      });
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "Password Reset Done",
          text: `${response.message}`,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Mail Not Sent, Please Try Again Later!",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Sign In",
        text: "Sorry Something Went Wrong.Please Try After Sometime",
      });
    }
  };

  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Create New Password"} route={"/create-new-password"} />
          <div className="card customlogcard">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Create a new password</h2>
              <p className="subtext">Create a new login password</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input className="form-control" type={passwordVisible ? "text" : "password"} placeholder="Password" id="password" required />
                  <span className="icon">
                    <img src="assets/images/lock.png" alt="lock" />
                  </span>
                  <span className="toggle-password" onClick={() => togglePasswordVisibility("password")}>
                    <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                  </span>
                </div>

                <div className="input-group">
                  <input className="form-control" type={confirmPasswordVisible ? "text" : "password"} placeholder="Confirm Password" id="confirm-password" required />
                  <span className="icon">
                    <img src="assets/images/lock.png" alt="lock" />
                  </span>
                  <span className="toggle-password" onClick={() => togglePasswordVisibility("confirmPassword")}>
                    <FontAwesomeIcon icon={confirmPasswordVisible ? faEyeSlash : faEye} />
                  </span>
                </div>

                <button type="submit" className="submitbtn">
                  Create password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default CreateNewPassword;
