import React, { useState, useEffect } from "react";

export default function Nav({ tabTitle, link }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  // Handle window resize to toggle between mobile and desktop views
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <div className="navbar-area">
        {/* Menu For tablet & mobile Device */}
        {isMobileView && (
          <div className="mobile-nav">
            <a href="/home" className="logo">
              <img className="img-fluid" src="assets/images/logo.svg" alt="logo" />
            </a>
            <div className="other-option-responsive">
              <a href={link} className="custombtn btn-1 hover-filled-slide-down">
                <span>{tabTitle}</span>
              </a>
            </div>
            <div className="menu-toggle" onClick={toggleMobileMenu} aria-expanded={isMobileMenuOpen} aria-controls="mobile-menu">
              <i className={`fa ${isMobileMenuOpen ? "fa-times" : "fa-bars"}`} />
            </div>
          </div>
        )}

        {/* Menu For Desktop Device */}
        {!isMobileView && (
          <div className="main-nav">
            <div className="header-top">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-8">
                    <div className="header-top-contact">
                      <ul>
                        <li>
                          <a href="mailto:info@heartvantage.com">Email : info@heartvantage.ca</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-bottom">
              <div className="container">
                <nav className="navbar navbar-expand-md navbar-light pb-1">
                  <a className="navbar-brand" href="/home">
                    <img className="img-fluid" src="assets/images/logo.svg" alt="logo" />
                  </a>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a href="/home" className="nav-link">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/about-us" className="nav-link">
                          About Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/contact-us" className="nav-link">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                    <div className="other-option d-none d-md-inline-block">
                      <a href={link} className="custombtn btn-1 hover-filled-slide-down">
                        <span>{tabTitle}</span>
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Menu (Toggle logic) */}
        {isMobileMenuOpen && isMobileView && (
          <div className="mobile-nav-menu" id="mobile-menu">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a href="/home" className="nav-link" onClick={toggleMobileMenu}>
                  Home
                </a>
              </li>
              <li className="nav-item dropdown">
                {/* for subitem dropdown use class dropdown-toggle */}
                <a href="/about-us" className="nav-link dropdown-menu-nav">
                  About Us
                </a>
                {/* <ul className="dropdown-menu">
                  <li>
                    <a href="/team" className="dropdown-item">
                      Our Team
                    </a>
                  </li>
                  <li>
                    <a href="/history" className="dropdown-item">
                      Our History
                    </a>
                  </li>
                  <li>
                    <a href="/mission" className="dropdown-item">
                      Our Mission
                    </a>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item">
                <a href="/contact-us" className="nav-link" onClick={toggleMobileMenu}>
                  Contact Us
                </a>
              </li>
            </ul>
            {/* <div className="other-option">
              <a href={link} className="custombtn btn-1 hover-filled-slide-down" onClick={toggleMobileMenu}>
                <span>{tabTitle}</span>
              </a>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
