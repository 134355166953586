// import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";

const ScreenPlay = () => {
  const options = {
    loop: true,
    margin: 30,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    items: 5,
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      992: {
        items: 5,
      },
    },
    // navText: ['<i class="fa-solid fa-angle-left"></i>', '<i class="fa-solid fa-angle-right"></i>'],
  };

  return (
    <section className="screenpaly">
      <div className="container">
        <div className="section-header">
          <h2>App Screen short</h2>
          <div className="divider">
            <span className="line"></span>
            <img src="assets/images/headingheart.svg" alt="Heart Icon" className="icon" />
            <span className="line"></span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel className="owl-carousel owl-carousel-2 owl-theme" options={options}>
              <div className="screens">
                <img src="assets/images/screen-one.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-two.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-three.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-four.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-five.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-six.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-seven.png" alt="screens" />
              </div>
              <div className="screens">
                <img src="assets/images/screen-eight.png" alt="screens" />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScreenPlay;
