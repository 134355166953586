import React, { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import FooterTop from "../Common/FooterTop";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import { forgotPassword } from "../../slices/action.js";
const ForgotPassword = () => {
  let navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({
    email: "",
  });
  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    const updatedUserDetail = {
      ...userDetail,
      [name]: value,
    };

    setUserDetail(updatedUserDetail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(userDetail);
      console.log("resonse====>", response, response.message);
      sessionStorage.setItem("email", userDetail.email);
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "Otp Sent In Your Mail Id",
          text: `${response.message} And Your Otp is ${response.r.otp}`,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/reset-password");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Mail Not Sent, Please Try Again Later!",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Sign In",
        text: "Sorry Something Went Wrong.Please Try After Sometime",
      });
    }
  };

  return (
    <div>
      <div className="loginnWrappermain">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Forgot Password"} route={"/forgot-password"} />
          <div className="card customlogcard">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Forgot your password?</h2>
              <p className="subtext">Enter your email address, we will send you a confirmation code. </p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input className="form-control" name="email" value={userDetail.email} onChange={handleChange} type="email" placeholder="johndoe@gmail.com" required />
                  <span className="icon">
                    <img src="assets/images/sms.png" alt="sms" />
                  </span>
                </div>
                <div className="forgot-link">
                  <button className="custombtn btn-2 hover-filled-slide-down">
                    <span>Password Reset</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default ForgotPassword;
