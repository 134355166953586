import React, { useState, useEffect } from "react";
import { DNA } from "react-loader-spinner";
import { getResult } from "../../slices/action.js";
import FooterTop from "../Common/FooterTop";
import { useNavigate } from "react-router";
import BreadCrumb from "../../Components/Common/BreadCrumb.js";
import Swal from "sweetalert2";
const Result = () => {
  let navigate = useNavigate();
  const [result, setResult] = useState(false);
  const [headers, setHeaders] = useState("");
  const [points, setPoints] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getResults();
  }, []);
  async function getResults() {
    if (localStorage.getItem("setID")) {
      try {
        let resp = await getResult({ setID: localStorage.getItem("setID") });
        if (resp.status) {
          let re = resp["r"]["g"]["answare"]["choices"][0]["message"]["content"];
          const [header, ...points] = re.split("\n").filter((line) => line.trim() !== "");
          console.log("rs=====>", re, header, points);
          setHeaders(header);
          setPoints(points);
          setResult(true);
          localStorage.removeItem("setID");
          localStorage.removeItem("questionID");
          localStorage.removeItem("answer");
        } else {
          Swal.fire({
            icon: "error",
            title: "Health Assessment",
            text: "Sorry, something went wrong. Please try again later.",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Health Assessment",
          text: "Sorry, something went wrong. Please try again later.",
        });
      }
    } else {
      navigate("/profile");
    }
  }

  return (
    <div>
      <div className="loginnWrappermain loginnWrappermain_content hover-filled-slide-down">
        <div className="container">
          <BreadCrumb routeHome={"/"} PageTitle={"Profile"} route={"/profile"} />
          {!result && (
            <div className="loader-wrapper ">
              <DNA visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
            </div>
          )}
          {/* <div className="card customlogcard">
            <div className="image-section">
              <img src="assets/images/login-Image.png" alt="Doctor" />
            </div>
            <div className="form-section">
              <h2>Enter confirmation code</h2>
              <p className="subtext">
                Enter the code we have sent to your email address <a href="mailto:johndoe@gmail.com">johndoe@gmail.com</a>
              </p>
              <form action="#">
                <div className="input-group otpwrap">
                  <input title="number" className="form-control" id="codeBox1" type="number" maxlength="1" onkeyup="onKeyUpEvent(1, event)" onfocus="onFocusEvent(1)" />
                  <input title="number" className="form-control" id="codeBox2" type="number" maxlength="1" onkeyup="onKeyUpEvent(2, event)" onfocus="onFocusEvent(2)" />
                  <input title="number" className="form-control" id="codeBox3" type="number" maxlength="1" onkeyup="onKeyUpEvent(3, event)" onfocus="onFocusEvent(3)" />
                  <input title="number" className="form-control" id="codeBox4" type="number" maxlength="1" onkeyup="onKeyUpEvent(4, event)" onfocus="onFocusEvent(4)" />
                </div>
                <div className="forgot-link">
                  <a className="custombtn btn-2 hover-filled-slide-down" href="password-reset.html">
                    <span>Sign In</span>{" "}
                  </a>
                </div>
              </form>
            </div>
          </div> */}
          <div className="section-header">
            <h2>Result Page</h2>
            <div className="divider">
              <span className="line"></span>
              <img src="assets/images/headingheart.svg" alt="Heart Icon" className="icon" />
              <span className="line"></span>
            </div>
          </div>
          <div className="assessment-container-area">
            <div className="heart-bg">
              <img src="assets/images/heart-bg.svg" alt="heart-bg" />
            </div>
            <div className="accordion resultaccordion" id="accordionExample">
              {result && (
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      {headers}
                    </button>
                  </h3>
                  <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul>
                        {points.map((point, index) => (
                          <li key={index}>{point.trim()}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingMedications">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMedications" aria-expanded="false" aria-controls="collapseMedications">
                    Medication Recommendation
                  </button>
                </h2>
                <div id="collapseMedications" className="accordion-collapse collapse" aria-labelledby="headingMedications" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>
                      <strong>Legal Disclaimer:</strong> The following medications should only be used under the supervision of a healthcare professional. Always consult your doctor before taking any medication.
                    </p>
                    <ul>
                      <li>
                        <strong>1 tablet Disprin brand Aspirin (325 mg):</strong> Chew for it to reach the bloodstream faster.
                      </li>
                      <li>
                        <strong>2 tablets Clopidogrel (75 mg each):</strong> Drink with water.
                      </li>
                      <li>
                        <strong>1 tablet Atorvastatin (80 mg):</strong> Drink with water.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingLifestyle">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLifestyle" aria-expanded="false" aria-controls="collapseLifestyle">
                    Lifestyle & Diet Recommendations for a Healthy Heart
                  </button>
                </h2>
                <div id="collapseLifestyle" className="accordion-collapse collapse" aria-labelledby="headingLifestyle" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>
                      At HeartSense, we believe that the key to maintaining a healthy heart involves more than just traditional treatments. We have created a short list of effective lifestyle changes you can make to ensure the well-being of your heart. Explore our extensive resources and begin your
                      journey towards better heart health down below.
                    </p>
                    <ul>
                      <li>
                        <strong>Active Lifestyle:</strong> Exercise and engage in 30 minutes of physical activity daily.
                      </li>
                      <li>
                        <strong>Nutritional Recommendations (Holistic Nutrition):</strong> Your heart health revolves around your everyday choices regarding your diet.
                      </li>
                      <li>
                        <strong>Ancient Yogic Practices for Heart:</strong> Engage in Guided Yogic Exercises for a healthy heart.
                      </li>
                      <li>
                        <strong>Manage Your Stress:</strong> Long-term stress in your daily life can take a toll on your heart health.
                      </li>
                      <li>
                        <strong>Reduce Nicotine, Tobacco & Alcohol:</strong> The vitality of your heart is broken from the effects of Nicotine & Alcohol usage.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Health Diagnostic / Treatments
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <strong>CT Coronary Scan:</strong> This diagnostic test will determine the blockage and plaque build-up in your arteries. It is non-invasive and painless as it identifies any blockages in your arteries. Too much blockage in any major artery can cause a blood clot to form
                        which blocks the arteries resulting in a heart attack. Complete this test to identify your risk of heart attack.
                        <br />
                        <span>
                          <a style={{ top: "16px", marginBottom: "14px" }} href="https://theheartclinic.ca/#booka" target="_blank" className="custombtn btn-2 hover-filled-slide-down">
                            <span>Book Appointment</span>
                          </a>
                        </span>
                      </li>
                      <li>
                        <strong>ECG/EKG:</strong> This diagnostic test monitors the heartbeat by recording electrical signals in the heart. It is non-invasive and painless; however, it is still very effective in diagnosing heart arrhythmias and heart attack (exact type) which helps doctors determine
                        the most effective treatment in your case.
                        <br />
                        <span>
                          <a style={{ top: "16px", marginBottom: "14px" }} href="https://theheartclinic.ca/#booka" target="_blank" className="custombtn btn-2 hover-filled-slide-down">
                            <span>Book Appointment</span>
                          </a>
                        </span>
                      </li>

                      <li>
                        <strong>Blood Test:</strong> This test can measure your cholesterol and hemoglobin levels. Although it is slightly painful, the test is still relatively quick and easy to get through while being effective to determine heart attack risk. High cholesterol levels often result in
                        fat deposits in your blood vessels which can cause blockage and heart attacks. Additionally, irregular hemoglobin levels are another indicator for heart attack risk which is measured in this test.
                        <br />
                        <span>
                          <a style={{ top: "16px", marginBottom: "14px" }} href="https://theheartclinic.ca/#booka" target="_blank" className="custombtn btn-2 hover-filled-slide-down">
                            <span>Book Appointment</span>
                          </a>
                        </span>
                      </li>
                      <li>
                        <strong>EECP Treatment:</strong> EECP Treatment is a treatment which helps to improve blood flow in your body by applying pressure to lower limbs. Through EECP treatment patients experience a circulation improvement of 70% on average.
                        <br />
                        <span>
                          <a style={{ top: "16px", marginBottom: "14px" }} href="https://theheartclinic.ca/#booka" target="_blank" className="custombtn btn-2 hover-filled-slide-down">
                            <span>Book Appointment</span>
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    CT Coronary Scan
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    This diagnostic test will determine the blockage and plaque build-up in your arteries. It is non-invasive and painless as it identifies any blockages in your arteries. Too much blockage in any major artery can cause a blood clot to form which blocks the arteries resulting in a
                    heart attack. Complete this test to identify your risk of heart attack.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    ECG/EKG
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    This diagnostic test monitors the heartbeat by recording electrical signals in the heart. It is non-invasive and painless; however, it is still very effective in diagnosing heart arrhythmias and heart attack(exact type) which helps doctors determine the most effective treatment
                    in your case.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Blood Test
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    This test can measure your cholesterol and hemoglobin levels. Although it is slightly painful, the test is still relatively quick and easy to get through while being effective to determine heart attack risk. High cholesterol levels often result in fat deposits in your blood
                    vessels which can cause blockage and heart attacks. Additionally, irregular hemoglobin levels are another indicator for heart attack risk which is measured in this test.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    EECP Treatment
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">EECP Treatment is a treatment which helps to improve blood flow in your body by applying pressure to lower limbs. Through EECP treatment patients experience a circulation improvement of 70% on average.</div>
                </div>
              </div> */}

              {/* <div className="accordion-item">
                <h3 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Lifestyle Recommendations:
                  </button>
                </h3>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    Given your responses, it's essential to undergo specific diagnostic tests to monitor and manage potential health risks.
                    <ul>
                      <li>
                        <strong>Blood Pressure Monitoring:</strong>Regular checks to manage high blood pressure effectively.
                      </li>
                      <li>
                        <strong>HbA1c Test:</strong>To monitor your diabetes and control your blood sugar levels.
                      </li>
                      <li>
                        <strong>Lipid Profile Test:</strong>Assess cholesterol and triglyceride levels for cardiovascular health.
                      </li>
                      <li>
                        <strong>Cardiac Screening:</strong>Including ECG and stress tests due to a family history of heart-related issues.
                      </li>
                      <li>
                        <strong>BMI and Body Composition Analysis:</strong>To evaluate and address weight-related concerns.
                      </li>
                      <li>
                        <strong>Smoking Cessation Counseling:</strong>Evaluation of smoking-related lung health through a pulmonary function test.
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
    </div>
  );
};

export default Result;
