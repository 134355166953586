// import React, { useState } from "react";
import FooterTop from "./FooterTop";
import ScreenPlay from "./ScreenPlay";
const PrivacyPolicy = () => {
  return (
    <div>
      <div class="loginnWrappermain loginnWrappermain_content">
        <div class="container">
          <div class="inner-banner-txt">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)" class="active">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div class="card customlogcard">
            <div class="form-section contentpagewrapper">
              <h2>Privacy Policy</h2>
              <p>
                <strong>Effective Date:</strong> 27th November 2024
              </p>

              <p>
                HeartVantage is committed to protecting your privacy and ensuring the confidentiality of all personal and health-related information collected through our app. This Privacy Policy outlines how we collect, use, store, and protect your information, as well as your rights regarding your
                data. By using the HeartVantage app, you agree to the terms outlined in this Privacy Policy.
              </p>

              <h3>Information We Collect</h3>

              <p>
                <strong>Recommendations:</strong>
              </p>
              <p>
                <strong>Personal Information:</strong>Name, age, gender, and contact details (optional).
              </p>
              <p>
                <strong>Health Information</strong>Responses to health assessment questions, medical history, and lifestyle data.
              </p>
              <p>
                <strong>Device Information:</strong>Technical data such as device type, operating system, and app usage statistics.
              </p>

              <h3>How We Use Your Information</h3>
              <p>
                <strong>The information collected is used for:</strong>
              </p>
              <p>Generating personalized heart-health assessments and recommendations.</p>
              <p>Improving the accuracy and functionality of the app.</p>
              <p>Conducting anonymized research and analytics to enhance preventive healthcare strategies.</p>
              <p>We will never sell, share, or disclose your personal or health information to third parties without your explicit consent, except as required by law or as outlined in this policy.</p>

              <h3>Data Confidentiality and Security</h3>
              <p>
                <strong>HeartVantage employs robust security measures to protect your data, including:</strong>
              </p>
              <p>Encryption of all health data both in transit and at rest.</p>
              <p>Restricted access to your information, limited to authorized personnel.</p>
              <p>Regular audits and updates to ensure compliance with applicable privacy laws and regulations.</p>

              <h3>Data Retention</h3>
              <p>Your information is retained only for as long as necessary to provide the app's services or as required by applicable laws. You may request deletion of your data at any time by contacting us.</p>

              <h3>Your Rights</h3>
              <p>
                <strong>You have the following rights regarding your information</strong>
              </p>
              <p>
                <strong>Correction:</strong> Update or correct inaccuracies in your information.
              </p>
              <p>
                <strong>Deletion:</strong> Request the deletion of your information, subject to legal or regulatory obligations.
              </p>
              <p>
                <strong>Withdrawal of Consent:</strong> Withdraw your consent for data collection and usage, which may limit the app's functionality.
              </p>

              <h3>Sharing Your Information</h3>
              <p>
                <strong>Your information will not be shared with any third parties, except:</strong>
              </p>
              <p>To comply with legal or regulatory requirements.</p>
              <p>To protect the rights and safety of HeartVantage, its users, or the public.</p>
              <p>With your explicit consent, for purposes such as clinical research or third-party integrations.</p>

              <h3>Third-Party Services</h3>
              <p>The HeartVantage app may integrate with third-party tools or services to provide additional functionality. These services are governed by their own privacy policies, and HeartVantage is not responsible for their practices.</p>

              <h3>Changes to the Privacy Policy</h3>
              <p>We may update this Privacy Policy periodically. Users will be notified of significant changes through the app or via email. Continued use of the app constitutes acceptance of the revised policy.</p>

              <h3>Contact Us</h3>
              <p>
                <strong>For questions or concerns about this Privacy Policy, or to exercise your data rights, contact us at:</strong>
              </p>
              <p>
                <strong>Email:</strong> heartvantage@gmail
              </p>
              <p>
                <strong>Address:</strong> 5 Virginia Drive, Whitby, ON L1R 2Y1
              </p>

              <p>By using the HeartVantage app, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <FooterTop />
    </div>
  );
};

export default PrivacyPolicy;
